import {
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  format,
  isDate,
  parse,
} from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz'
import { DateFormat, TestLocation, TestSlot } from './types'

export const isServer = typeof window === 'undefined'

export const allowedCharsRegex = /^[0-9a-zA-Z-äöüÄÖÜß&\ ]+$/

export const allowedCharsWithNumbersRegex = /^[a-zA-Z0-9-äöüÄÖÜß\ ]+$/

export const randomString = ({
  length,
  withSymbols = false,
  withNumbers = true,
}: {
  length: number
  withSymbols?: boolean
  withNumbers?: boolean
}) => {
  let result = ''
  const specials = '!@#$%^&*'
  const numbers = '0123456789'
  const smallChars = 'abcdefghijklmnopqrstuvwxyz'
  const bigChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
  for (let i = 0; i < length / 4; i++) {
    if (withSymbols) {
      result += specials.charAt(Math.floor(Math.random() * specials.length))
    }
    if (withNumbers) {
      result += numbers.charAt(Math.floor(Math.random() * numbers.length))
    }
    result += smallChars.charAt(Math.floor(Math.random() * smallChars.length))
    result += bigChars.charAt(Math.floor(Math.random() * bigChars.length))
  }
  return result
}

export const getRequest = async <S = any>({
  endPoint,
  authToken,
  queryParams,
}: {
  endPoint: string
  authToken: string
  queryParams?: Record<string, any>
}) => {
  const response = await fetch(
    `${process.env.NEXT_PUBLIC_API_URL}${endPoint}${
      queryParams ? `?${createQueryParams(queryParams)}` : ''
    }`,
    { headers: { 'Auth-Token': authToken } }
  ).then((res) => res.json())

  return response as S
}

export const deleteRequest = async ({
  endPoint,
  authToken,
  body,
}: {
  endPoint: string
  authToken: string
  body?: Record<string, any>
}) => {
  const response = await fetch(
    `${process.env.NEXT_PUBLIC_API_URL}${endPoint}`,
    {
      headers: { 'Auth-Token': authToken },
      method: 'DELETE',
      ...(body && { body: JSON.stringify(body) }),
    }
  ).then((res) => res.json())

  return response
}

export const postRequest = async ({
  authToken,
  endPoint,
  variables,
}: {
  authToken: string
  endPoint: string
  variables: object
}) => {
  const response = await fetch(
    `${process.env.NEXT_PUBLIC_API_URL}${endPoint}`,
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'Auth-Token': authToken },
      body: JSON.stringify(variables),
    }
  ).then((res) => res.json())

  return response
}

export const putRequest = async ({
  authToken,
  endPoint,
  variables,
}: {
  authToken: string
  endPoint: string
  variables: object
}) => {
  const response = await fetch(
    `${process.env.NEXT_PUBLIC_API_URL}${endPoint}`,
    {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json', 'Auth-Token': authToken },
      body: JSON.stringify(variables),
    }
  ).then((res) => res.json())

  return response
}

export const dateTimeFormat = 'dd.MM.yyyy - HH:mm'

export const timeFormat = 'HH:mm'

export const dateFormat = 'dd.MM.yyyy'

export const apiDateFormat = 'yyyy-MM-dd'

export const apiDateTimeFormat = 'yyyy-MM-dd HH:mm:ss'

const getDateTimeFormat = (dateString: DateFormat) => {
  switch (dateString) {
    case 'daytime':
      return dateTimeFormat

    case 'day':
      return dateFormat

    case 'time':
      return timeFormat

    case 'apiDate':
      return apiDateFormat

    case 'apiDateTime':
      return apiDateTimeFormat
  }
}

export const isValidDate = (d: any) => {
  return d instanceof Date && !isNaN(d as any)
}

/**
 * Parses a human-readable date (most of the time created by formatDate) to a Date Object
 * @example
 * // returns Date Tue Feb 02 2021 00:00:00 GMT+0000 (Coordinated Universal Time)
 * parseDate(02.02.2021, 'day');
 * @example
 * // returns Date Tue Feb 02 2021 18:35:00 GMT+0000 (Coordinated Universal Time)
 * parseDate("18:35", 'time');
 */
export const parseDate = (date: string, dateString: DateFormat) => {
  return parse(date, getDateTimeFormat(dateString), new Date())
}

/**
 * Converts a Date into a human-readable format
 * @example
 * // returns 02.02.2021
 * formatDate(new Date("Tue Feb 02 2021"), 'day');
 * @example
 * // returns 18:35
 * formatDate("2021-02-02T18:35:39.100Z", 'time');
 */
export const formatDate = (
  date: string | number | Date,
  dateString: DateFormat
) => {
  if (!date || date === 'N/A') return date

  const actualDate = isDate(date) ? (date as Date) : new Date(date)

  return format(actualDate, getDateTimeFormat(dateString))
}

export const getNextAvailableSlot = (
  slots: TestSlot[],
  customerAmount: number
) => {
  if (!slots) return null

  for (const slot of slots) {
    if (slot.capacity - slot.reserved >= customerAmount) {
      return slot
    }
  }
  return null
}

export const getMaximumCustomerAmount = (slots: TestSlot[]) => {
  if (!slots) return null

  let maximumAmount = 1

  for (const slot of slots) {
    if (slot.capacity - slot.reserved > maximumAmount) {
      maximumAmount = slot.capacity - slot.reserved
    }
  }

  return maximumAmount
}

export const formatSeconds = (seconds: number) => {
  let min: String = ''
  let sec: String = ''

  if (seconds >= 60) {
    let tmp = Math.floor(seconds / 60)
    min = tmp > 9 ? tmp.toString() : '0' + tmp
  } else min = '00'

  if (seconds % 60 > 0) {
    let tmp: number = seconds % 60
    sec =
      tmp > 9 ? Math.round(tmp).toString() : '0' + Math.round(tmp).toString()
  } else sec = '00'

  return min + ':' + sec
}

export const fromUnix = (timestamp: number) => {
  const date = utcToZonedTime(timestamp * 1000, 'Europe/Berlin')

  return date
}

export const calculateDistance = (
  point1: { lat: number; lng: number },
  point2: { lat: number; lng: number }
) => {
  const radlat1 = (Math.PI * point1.lat) / 180
  const radlat2 = (Math.PI * point2.lat) / 180
  const radlon1 = (Math.PI * point1.lng) / 180
  const radlon2 = (Math.PI * point2.lng) / 180
  const theta = point1.lng - point2.lng
  const radtheta = (Math.PI * theta) / 180
  let dist =
    Math.sin(radlat1) * Math.sin(radlat2) +
    Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta)
  dist = Math.acos(dist)
  dist = (dist * 180) / Math.PI
  dist = dist * 60 * 1.1515

  return dist
}

export const parseCookies = (req: any) => {
  const list: any = {}

  req.headers.cookie?.split(';')?.forEach(function (cookie) {
    const parts = cookie.split('=')
    list[parts.shift().trim()] = decodeURI(parts.join('='))
  })

  return list
}

export const createQueryParams = (params: any) =>
  Object.keys(params)
    .map((k) => `${k}=${encodeURI(params[k])}`)
    .join('&')

export const generateSlug = (text: string) =>
  text
    .toString()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase()
    .trim()
    .replace(/\s+/g, '-')
    .replace(/[^\w-]+/g, '')
    .replace(/--+/g, '-')

export const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export const formatDateRelative = (date: Date, dateLeft?: Date) => {
  const secondDate = dateLeft ?? utcToZonedTime(new Date(), 'Europe/Berlin')

  const minuteDiff = Math.abs(differenceInMinutes(secondDate, date))

  if (minuteDiff <= 60) {
    return `${minuteDiff} ${minuteDiff === 1 ? 'Minute' : 'Minuten'} `
  }

  const hourDiff = Math.abs(differenceInHours(secondDate, date))
  if (hourDiff <= 24) {
    return `${hourDiff} ${hourDiff === 1 ? 'Stunde' : 'Stunden'}`
  }

  const dayDiff = Math.abs(differenceInDays(secondDate, date))

  if (hourDiff - dayDiff * 24 === 0) {
    return `${dayDiff} ${dayDiff === 1 ? 'Tag' : 'Tage'}`
  }

  return `${dayDiff} ${dayDiff === 1 ? 'Tag' : 'Tage'} und ${
    hourDiff - dayDiff * 24
  } ${hourDiff - dayDiff * 24 === 1 ? 'Stunde' : 'Stunden'}`
}

export const formatPrice = (price: number) => {
  return (price / 100).toFixed(2).replace('.', ',')
}

export const prettyTestType = (testType: string, t: any) => {
  const type = testType.toLowerCase()
  return type === 'pcr'
    ? t('pcrTestHeader')
    : type === 'express'
    ? t('expressTestHeader')
    : type === 'antigen'
    ? t('antigenTestHeader')
    : type === 'sameday'
    ? t('samedayTestHeader')
    : type === 'oegd'
    ? t('oegdTestHeader')
    : ['poc_pcr', 'pocpcr'].includes(type)
    ? t('pocpcrTestHeader')
    : ['poc_express', 'pocexpress'].includes(type)
    ? t('pocexpressTestHeader')
    : ['poc_sameday', 'pocsameday'].includes(type)
    ? t('pocsamedayTestHeader')
    : type === 'antigensemi'
    ? 'Subventionierter Antigen Test'
    : t('antigenFreeTestHeader')
}

export const isLocalStorage = () => {
  var test = 'test'
  try {
    localStorage.setItem(test, test)
    localStorage.removeItem(test)
    return true
  } catch (e) {
    return false
  }
}

export const getStationTestTypes = (station: TestLocation) => {
  const kinds = [
    { isActive: station.pcr, name: 'pcr' },
    { isActive: station.antigenfree, name: 'antigenfree' },
    { isActive: station.express, name: 'express' },
    { isActive: station.sameday, name: 'sameday' },
    { isActive: station.antigen, name: 'antigen' },
    { isActive: station.oegd, name: 'oegd' },
    { isActive: station.poc_pcr, name: 'pocpcr' },
    { isActive: station.poc_express, name: 'pocexpress' },
    { isActive: station.poc_sameday, name: 'pocsameday' },
  ]

  return kinds.filter((k) => k.isActive).map((k) => k.name.toUpperCase())
}

export const uniquifyArray = (array: any[]) => {
  const seen = new Set()
  return array.filter((item) => {
    const duplicate = seen.has(item)
    seen.add(item)
    return !duplicate
  })
}
