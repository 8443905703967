import { Referral } from './types'

export const testKinds = (t: any) => [
  {
    name: ['PCR'],
    title: t('pcrTestHeader'),
    description: t('pcrTestDescription'),
    reasons: [t('pcrArgument1'), t('pcrArgument2'), t('pcrArgument3')],
    price: t('pcrPrice'),
    alt: 'PCR',
    color: '#36B9E8',
    short: 'PCR',
  },
  {
    name: ['ANTIGEN'],
    title: t('antigenTestHeader'),
    description: t('antigenTestDescription'),
    reasons: [
      t('antigenArgument1'),
      t('antigenArgument2'),
      t('antigenArgument3'),
    ],
    price: t('antigenPrice'),
    alt: 'Antigen',
    color: '#F7C136',
    short: 'Schnelltest',
  },
  {
    name: ['ANTIGENFREE'],
    title: t('antigenFreeTestHeader'),
    description: t('antigenFreeTestDescription'),
    reasons: [
      t('antigenFreeArgument2'),
      t('antigenFreeArgument3'),
      t('antigenFreeArgument1'),
    ],
    price: t('antigenFreePrice'),
    alt: 'Antigenfree',
    color: '#F7C136',
    short: 'Schnelltest',
  },
  {
    name: ['EXPRESS'],
    title: t('expressTestHeader'),
    description: t('expressTestDescription'),
    reasons: [
      t('expressArgument1'),
      t('expressArgument2'),
      t('expressArgument3'),
    ],
    price: t('expressPrice'),
    alt: 'Express',
    color: '#c036f7',
    short: 'EXPRESS PCR',
  },
  {
    name: ['SAMEDAY'],
    title: t('samedayTestHeader'),
    description: t('samedayTestDescription'),
    reasons: [
      t('samedayArgument1'),
      t('samedayArgument2'),
      t('samedayArgument3'),
    ],
    price: t('samedayPrice'),
    alt: 'Sameday',
    color: '#c036f7',
    short: 'SAMEDAY',
  },

  {
    name: ['OEGD'],
    title: t('oegdTestHeader'),
    description: t('oegdTestDescription'),
    reasons: [t('oegdArgument1'), t('oegdArgument2'), t('oegdArgument3')],
    price: t('oegdPrice'),
    alt: 'oegd',
    color: '#22C55E',
    short: 'Free PCR',
  },
  {
    name: ['POCPCR', 'POC_PCR'],
    title: t('pocpcrTestHeader'),
    description: t('pocpcrTestDescription'),
    reasons: [t('pocpcrArgument1'), t('pocpcrArgument2'), t('pocpcrArgument3')],
    price: t('pocpcrPrice'),
    alt: 'pocpcr',
    color: '#36B9E8',
    short: 'PCR',
  },
  {
    name: ['POCEXPRESS', 'POC_EXPRESS'],
    title: t('pocexpressTestHeader'),
    description: t('pocexpressTestDescription'),
    reasons: [
      t('pocexpressArgument1'),
      t('pocexpressArgument2'),
      t('pocexpressArgument3'),
    ],
    price: t('pocexpressPrice'),
    alt: 'pocexpress',
    color: '#c036f7',
    short: 'EXPRESS PCR',
  },
  {
    name: ['POCSAMEDAY', 'POC_SAMEDAY'],
    title: t('pocsamedayTestHeader'),
    description: t('pocsamedayTestDescription'),
    reasons: [
      t('pocsamedayArgument1'),
      t('pocsamedayArgument2'),
      t('pocsamedayArgument3'),
    ],
    price: t('pocsamedayPrice'),
    alt: 'pocsameday',
    color: '#c036f7',
    short: 'SAMEDAY PCR',
  },
]

export const menuItems = (t: any) => [
  {
    label: t('menuHeader-1'),
    description: t('menuDescription-1'),
    href: '/pcr',
    image: '/womanMask.png',
    alt: t('menuAlt-1'),
    Icon: (
      <svg
        className='flex-shrink-0 w-6 h-6 text-primary-500'
        fill='currentColor'
        viewBox='0 0 20 20'
        xmlns='http://www.w3.org/2000/svg'>
        <path
          fillRule='evenodd'
          d='M7 2a1 1 0 00-.707 1.707L7 4.414v3.758a1 1 0 01-.293.707l-4 4C.817 14.769 2.156 18 4.828 18h10.343c2.673 0 4.012-3.231 2.122-5.121l-4-4A1 1 0 0113 8.172V4.414l.707-.707A1 1 0 0013 2H7zm2 6.172V4h2v4.172a3 3 0 00.879 2.12l1.027 1.028a4 4 0 00-2.171.102l-.47.156a4 4 0 01-2.53 0l-.563-.187a1.993 1.993 0 00-.114-.035l1.063-1.063A3 3 0 009 8.172z'
          clipRule='evenodd'
        />
      </svg>
    ),
  },
  {
    label: t('menuHeader-2'),
    description: t('menuDescription-2'),
    href: '/antigen',
    image: '/pigMask.png',
    alt: t('menuAlt-2'),
    Icon: (
      <svg
        className='flex-shrink-0 w-6 h-6 text-primary-500'
        fill='currentColor'
        viewBox='0 0 20 20'
        xmlns='http://www.w3.org/2000/svg'>
        <path
          fillRule='evenodd'
          d='M11.3 1.046A1 1 0 0112 2v5h4a1 1 0 01.82 1.573l-7 10A1 1 0 018 18v-5H4a1 1 0 01-.82-1.573l7-10a1 1 0 011.12-.38z'
          clipRule='evenodd'
        />
      </svg>
    ),
  },
  {
    label: t('menuHeader-3'),
    description: t('menuDescription-3'),
    href: '/firmen',
    image: '/closeMask.png',
    alt: t('menuAlt-3'),
    Icon: (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        className='flex-shrink-0 w-6 h-6 text-primary-500'
        viewBox='0 0 20 20'
        fill='currentColor'>
        <path
          fillRule='evenodd'
          d='M6 6V5a3 3 0 013-3h2a3 3 0 013 3v1h2a2 2 0 012 2v3.57A22.952 22.952 0 0110 13a22.95 22.95 0 01-8-1.43V8a2 2 0 012-2h2zm2-1a1 1 0 011-1h2a1 1 0 011 1v1H8V5zm1 5a1 1 0 011-1h.01a1 1 0 110 2H10a1 1 0 01-1-1z'
          clipRule='evenodd'
        />
        <path d='M2 13.692V16a2 2 0 002 2h12a2 2 0 002-2v-2.308A24.974 24.974 0 0110 15c-2.796 0-5.487-.46-8-1.308z' />
      </svg>
    ),
  },
  {
    label: t('menuHeader-4'),
    href: '/kostenlos',
    Icon: (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        className='flex-shrink-0 w-6 h-6 text-primary-500'
        fill='none'
        viewBox='0 0 24 24'
        stroke='currentColor'>
        <path
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth={2}
          d='M18.364 5.636l-3.536 3.536m0 5.656l3.536 3.536M9.172 9.172L5.636 5.636m3.536 9.192l-3.536 3.536M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-5 0a4 4 0 11-8 0 4 4 0 018 0z'
        />
      </svg>
    ),
  },
]

export const features = (t: any) => [
  {
    text: t('feature-1'),
  },
  {
    text: t('feature-2'),
  },
  {
    text: t('feature-3'),
  },
  {
    text: t('feature-4'),
  },
]

export const referralItems: Referral[] = [
  {
    href: 'https://www.welt.de/regionales/hamburg/article222806656/Corona-Test-vor-Weihnachten-Wo-Sie-sich-in-Hamburg-noch-testen-lassen-koennen.html',
    image: '/weltn24.png',
    alt: 'Welt & N24',
    height: 35,
    width: 183,
  },
  {
    href: 'https://www.zdf.de/nachrichten/zdfheute-live/weihnachten-corona-schnelltest-video-100.html',
    image: '/zdf.png',
    alt: 'ZDF',
    height: 80,
    width: 128,
  },
  {
    href: 'https://www.n-tv.de/regionales/hamburg-und-schleswig-holstein/Kurz-vor-Weihnachten-grosse-Nachfrage-nach-Corona-Tests-article22245085.html',
    image: '/ntv.png',
    alt: 'NTV',
    height: 50,
    width: 80,
  },
  {
    href: 'https://www.zeit.de/hamburg/2020-10/elbvertiefung-29-10-2020',
    image: '/zeit.png',
    alt: 'Die Zeit',
    height: 40,
    width: 200,
  },
  {
    href: 'https://www.spiegel.de/panorama/private-initiativen-gegen-corona-mit-schnelltests-aus-der-pandemie-spiegel-tv-a-c9278976-08a6-4624-9156-b42e70dce38d',
    image: '/spiegel.png',
    alt: 'Spiegel TV',
    height: 40,
    width: 200,
  },
  {
    image: '/rtl.png',
    alt: 'RTL',
    height: 40,
    width: 180,
  },
]

export const footerItems = (t: any) => [
  [
    {
      label: t('agb'),
      href: '/agb',
    },
    {
      label: t('dataProtection'),
      href: '/datenschutz',
    },
    {
      label: 'Presse',
      href: '/presse',
    },
    {
      label: 'Partner',
      href: '/partner',
    },
  ],
  [
    {
      label: t('questions'),
      href: '/#faq',
    },
    { label: t('imprint'), href: '/impressum' },
    { label: t('jobs'), href: '/jobs' },
  ],
]

export const antigenfreeOptions = [
  {
    label: 'Schwanger im ersten Trimester.',
    value: 'antigen_reason_pregnant',
  },
  {
    label: 'Medizinische Kontraindikation gegen Covid Impfung.',
    value: 'antigen_reason_medical_exemption',
  },
  {
    label: 'In Quarantäne.',
    value: 'antigen_reason_quarantine',
  },
  {
    label: 'Teilnehmer an klinischer Impfstoff-Studie.',
    value: 'antigen_reason_vaccination_study',
  },
  {
    label: 'Person, die mit einer infizierten Person im selben Haushalt lebt.',
    value: 'antigen_reason_household_contact',
  },
  {
    label: 'Pflegeperson nach §19 Satz 1 Sozialgesetzbuch.',
    value: 'antigen_reason_caregiver',
  },
  {
    label: 'Behindert, bzw. deren Betreuungskraft.',
    value: 'antigen_reason_handicapped',
  },
]

export const antigenSemiOptions = [
  {
    label: 'Besuch von Klink oder Pflegeeinrichtung.',
    value: 'antigen_reason_hospital_visit',
  },
  {
    label: 'Rote Kachel in der CWA',
    value: 'antigen_reason_cwa_contact',
  },
  {
    label: 'Besuch einer Veranstaltung im Innenraum am selben Tag.',
    value: 'antigen_reason_event',
  },
  {
    label: 'Besuch von Person älter 60 Jahre.',
    value: 'antigen_reason_visit',
  },
]
