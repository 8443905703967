import { DefaultSeo, LogoJsonLd } from 'next-seo'
import Head from 'next/head'
import { FC } from 'react'

export const baseUrl = 'https://coronafreepass.de'

const Meta: FC<{ title?: string }> = ({ title }) => {
  const composedTitle = title ? `Corona Freepass ${title}` : 'Corona Freepass'

  const description =
    'Corona Freepass bietet dir garantiert den günstigen Coronatest in Hamburg (oder jeweiliger anderer Standort). ✔ Schnell ✔ Schmerzfrei ✔ PCR Test ✔ Antigen Schnelltest'

  const logo = `${baseUrl}/banner.png`

  return (
    <>
      <Head>
        <meta charSet='utf-8' />
        <link rel='shortcut icon' href='/logo_32.png' />
        <meta httpEquiv='X-UA-Compatible' content='IE=edge'></meta>
        <meta
          name='facebook-domain-verification'
          content='b4o2aouq6tgbmdrlgyv85ghpe1d7gi'
        />
        <title>{composedTitle}</title>
      </Head>
      <LogoJsonLd logo={logo} url={baseUrl} />
      <DefaultSeo
	dangerouslySetAllPagesToNoIndex
        openGraph={{
          type: 'website',
          locale: 'de_DE',
          site_name: 'Corona Freepass',
          description,
          title: composedTitle,
          url: baseUrl,
          images: [
            {
              url: logo,
              alt: 'Corona Freepass',
              height: 87,
              width: 519,
            },
          ],
        }}
        title={composedTitle}
        description={description}
      />
    </>
  )
}

export default Meta
