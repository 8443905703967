import useTranslation from 'next-translate/useTranslation'
import Link from 'next/link'
import React, { FC } from 'react'
import { footerItems } from '../lib/content'

const Footer: FC<Props> = ({}) => {
  const { t } = useTranslation('common')
  return (
    <footer className='z-10 flex flex-col items-center justify-around w-full py-8 no-print md:flex-row bg-primary-500'>
      <Link href='/'>
        <a>
          <span className='sr-only'>Home</span>
          <img src='/footer-logo.png' alt='Logo' width={170} height={29} />
        </a>
      </Link>
      {footerItems(t).map((item, ind) => (
        <ul key={ind} className='mt-4 space-y-4 text-center md:text-left'>
          {item.map(({ href, label }) => {
            if (href.indexOf('http') !== -1) {
              return (
                <li key={href}>
                  <a
                    href={href}
                    rel='noreferrer'
                    target='_blank'
                    className='text-base text-white uppercase hover:text-gray-200'>
                    {label}
                  </a>
                </li>
              )
            }

            return (
              <li key={href}>
                <Link href={href}>
                  <a className='text-base text-white uppercase hover:text-gray-200'>
                    {label}
                  </a>
                </Link>
              </li>
            )
          })}
        </ul>
      ))}
      <div className='flex flex-col items-center lg:items-start'>
        <img
          src='/footer-icon-trash.png'
          alt='Gib Covid keine Chance!'
          width={120}
          height={50}
        />
        <span className='mt-2 text-sm text-gray-400'>
          <a href='tel:+494023830500'>{t('forQuestions')} +49 40 238305 00</a>
        </span>
        <span className='mt-2 text-sm text-gray-400'>
          oder per E-mail: support<span className='hidden'>WOLOLOLOLOLO</span>
          @coronafreepass.de
        </span>
      </div>
    </footer>
  )
}

export default Footer

interface Props {}
